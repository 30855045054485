import React from 'react'
import HeroSection from '../HeroSection/HeroSection'
import TransformationProgram from '../TransformationProgram/TransformationProgram'
import HowItWorks from '../HeroSection/HowItWorks/HowItWorks'
import ImageCarousel from '../ImageCarousel/ImageCarousel'
import ImageCarouselT from '../ImageCarousel/ImageCarouselT'
import ProfilePage from '../profile/ProfilePage'
import Facts from '../Fact/Facts'
import FAQ from '../FAQItem/FAQItem'
import LogoMarqueeSection from '../LogoMarqueeSection/LogoMarqueeSection'
import AppreciationPage from '../AppreciationPage/AppreciationPage'
import Footer from '../Footer/Footer'
import ButtonPage from '../ButtonPage/ButtonPage'

const Home = () => {
  return (
    <div>
      {/* Meta Pixel Code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '991490419593476');
            fbq('track', 'PageView');
          `
        }}
      />
      <noscript>
        <img
          height="1"
          width="1" 
          style={{display:'none'}}
          src="https://www.facebook.com/tr?id=991490419593476&ev=PageView&noscript=1"
          alt="meta-pixel-fallback"
        />
      </noscript>

      {/* Page Content */}
      <HeroSection/>
      <ProfilePage/>
      <LogoMarqueeSection/>
      <AppreciationPage/>
      <ImageCarousel/>
      <TransformationProgram/>
      <ImageCarouselT/>
      <Facts/>
      <FAQ/>
      <ButtonPage/>
      <Footer/>
    </div>
  )
}

export default Home