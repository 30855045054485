import React from 'react';
import Lottie from 'react-lottie';
import animationData from './animation.json';
import './ThankYouPage.css';

const ThankYouPage = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return (
    <div className="thank-you-container">
      {/* Combined Meta Pixel Code */}
      <script
        dangerouslySetInnerHTML={{
          __html: `
            !function(f,b,e,v,n,t,s)
            {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
            n.callMethod.apply(n,arguments):n.queue.push(arguments)};
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window, document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            
            // Initialize both pixels
            fbq('init', '1437995534253179');
            fbq('init', '991490419593476');
            
            // Track events for both pixels
            fbq('track', 'PageView');
            fbq('track', 'Lead');
          `
        }}
      />
      <noscript>
        {/* First Pixel Fallback */}
        <img height="1" width="1" style={{display:'none'}}
          src="https://www.facebook.com/tr?id=1437995534253179&ev=PageView&noscript=1"
        />
        {/* Second Pixel Fallback */}
        <img height="1" width="1" style={{display:'none'}}
          src="https://www.facebook.com/tr?id=991490419593476&ev=PageView&noscript=1"
        />
      </noscript>

      <div className="content-wrapper">
        <h1 className="thank-you-heading">
          Your Call Has Been Successfully Scheduled
        </h1>
        
        <div className="animation-container">
          <Lottie options={defaultOptions} />
        </div>

        <p className="followup-text">
          We're looking forward to connecting with you.
        </p>
      </div>
    </div>
  );
};

export default ThankYouPage;