import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { AiFillHome } from "react-icons/ai";
import { BsLink45Deg } from "react-icons/bs";
import "./AdminDashboard.css";

const AdminDashboard = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("isAuthenticated");
    navigate("/login");
  };

  return (
    <div className="admin-dashboard">
      <aside className="sidebar">
        <h2 className="logo">Admin Panel</h2>
        <nav>
          <ul>
            <li>
              <Link to="/admingfvfsdr1">
                <BsLink45Deg className="icon" /> First Link
              </Link>
            </li>
            <li>
              <Link to="/admingfvfsdr12">
                <AiFillHome className="icon" /> Home Link
              </Link>
            </li>
          </ul>
        </nav>
        <button className="logout-button" onClick={handleLogout}>
          Logout
        </button>
      </aside>

      <main className="content">
        <h1>Welcome to Admin Dashboard</h1>
        <p>Manage your admin settings from here.</p>
      </main>
    </div>
  );
};

export default AdminDashboard;
