import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { textdb } from "../../firebaseConfig"; // Firestore instance
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import "./ButtonPage.css";

const ButtonPage1 = () => {
  const [bikingLink, setBikingLink] = useState("");

  // Fetch biking link from Firestore
  useEffect(() => {
    const fetchBikingLink = async () => {
      try {
        const docRef = doc(textdb, "settings", "general1"); // Fetching from 'general1'
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBikingLink(docSnap.data().bikingLink);
        }
      } catch (error) {
        console.error("Error fetching biking link: ", error);
      }
    };

    fetchBikingLink();
  }, []);

  return (
    <div className="button-page">
      <ToastContainer position="top-right" autoClose={3000} />
      {bikingLink ? (
        <Link to={bikingLink}>
          <button className="hero-button">Book a Free Call Now</button>
        </Link>
      ) : (
        <p></p>
      )}
    </div>
  );
};

export default ButtonPage1;
