import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './ImageCarousel.css'; // Import your custom CSS for styling
import ButtonPage from '../ButtonPage/ButtonPage';
import ButtonPage1 from '../ButtonPage/ButtonPage1';

const images = [
  '/Image/c1.avif', 
 '/Image/c2.avif', 
 '/Image/c3.avif', 
 '/Image/c4.avif', 
 '/Image/c5.jpeg', 
 '/Image/c6.jpeg', 
 '/Image/c7.jpeg', 
 '/Image/c8.jpeg', 
];

const ImageCarousel1 = () => {
  const settings = {
    centerMode: true,
    centerPadding: '0', // No padding, as we want the images to align precisely
    slidesToShow: 3, // Show 3 images at once
    focusOnSelect: true, // Allow users to focus the center image by clicking
    infinite: true, // Infinite looping of slides
    speed: 500, // Animation speed
    autoplay: true, // Enable autoplay
    autoplaySpeed: 3000, // Delay between transitions (3 seconds)
    cssEase: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)', // Smooth easing function
    dots: true, // Show navigation dots
    arrows: true, // Enable previous/next arrows
    responsive: [
      {
        breakpoint: 1024, // Tablet view
        settings: {
          slidesToShow: 2, // Show 2 slides on tablet
          slidesToScroll: 1,
          centerMode: true,
        }
      },
      {
        breakpoint: 768, // Mobile view
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ]
  };

  return (
    <div className='carousel-container-main'>
    <div className="carousel-container">
      <h1 className='cch1'> Epic Transformations</h1>
      <Slider {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-slide">
            <img src={image} alt={`Slide ${index + 1}`} className="carousel-image" />
          </div>
        ))}
      </Slider>
    </div>
    <div>
    <ButtonPage1/>
    </div>
    </div>
  );
};

export default ImageCarousel1;
