
import './App.css';
import Home from './Components/Home/Home';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
import { Route, Routes } from 'react-router-dom';
import React, { useEffect } from 'react';
import AdminPage from './Components/AdminPage/AdminPage';
import HomeNew from './Components/Home/HomeNew';
import AdminPanel from './Components/AdminPanel/AdminPanel';
import AdminPanel1 from './Components/AdminPanel/AdminPanel1';
import ProtectedRoute from './Components/AdminPanel/ProtectedRoute';
import AdminDashboard from './Components/AdminPanel/AdminDashboard';
import Login from './Components/AdminPanel/Login';
import ThankYouPage from './Components/ThankYou/ThankYouPage';
import ThankYou from './Components/ThankYou/ThankYou';
function App() {
  useEffect(() => {
    AOS.init({
      duration: 1000, 
      once: true, 
      easing: 'ease-out',
    });
  }, []);
  return (
    <div className="App">
    
      <Routes>
    <Route exact path="/home" element={<Home />} />
    <Route exact path="/" element={<HomeNew />} />

      
      <Route exact path="/admingfvfsdr" element={<AdminPage/>} />
      <Route exact path="/admingfvfsdr1" element={<AdminPanel/>} />
      <Route exact path="/admingfvfsdr12" element={<AdminPanel1/>} />
      <Route exact path="/login" element={<Login/>} />
      <Route exact path="/thank-you" element={<ThankYouPage/>} />
      <Route exact path="/thankyou" element={<ThankYou/>} />



      <Route
          path="/admin"
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          }
        />




</Routes>
    </div>
  );
}

export default App;
