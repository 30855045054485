import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { textdb } from "../../firebaseConfig"; // Firestore instance
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminPanel.css";

const AdminPanel = () => {
  const [bikingLink, setBikingLink] = useState("");

  useEffect(() => {
    const fetchBikingLink = async () => {
      try {
        const docRef = doc(textdb, "settings", "general");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBikingLink(docSnap.data().bikingLink);
        }
      } catch (error) {
        console.error("Error fetching biking link: ", error);
      }
    };

    fetchBikingLink();
  }, []);

  const handleUpdate = async () => {
    try {
      const docRef = doc(textdb, "settings", "general");
      await setDoc(docRef, { bikingLink }, { merge: true });

      toast.success("Biking link updated successfully!");
    } catch (error) {
      toast.error("Error updating biking link: " + error.message);
      console.error("Error updating biking link: ", error);
    }
  };

  return (
    <div className="admin-panel">
      <ToastContainer position="top-right" autoClose={3000} />
      <h2>Update Biking Link</h2>
      <form onSubmit={(e) => e.preventDefault()}>
        <label htmlFor="bikingLink">Biking Link:</label>
        <input
          id="bikingLink"
          type="text"
          value={bikingLink}
          onChange={(e) => setBikingLink(e.target.value)}
          placeholder="Enter new biking link"
        />
        <button type="button" onClick={handleUpdate}>
          Update Link
        </button>
      </form>
    </div>
  );
};

export default AdminPanel;
