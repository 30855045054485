import React, { useState, useEffect } from "react";
import { doc, getDoc } from "firebase/firestore";
import { textdb } from "../../firebaseConfig";
import { Link } from "react-router-dom";

const ButtonPage = () => {
  const [bikingLink, setBikingLink] = useState("");

  useEffect(() => {
    const fetchBikingLink = async () => {
      try {
        const docRef = doc(textdb, "settings", "general");
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBikingLink(docSnap.data().bikingLink);
        }
      } catch (error) {
        console.error("Error fetching biking link: ", error);
      }
    };

    fetchBikingLink();
  }, []);

  return (
    <div className="button-page">
      {bikingLink && (
        <Link to={bikingLink}>
          <button className="hero-button">Book a Free Call Now</button>
        </Link>
      )}
    </div>
  );
};

export default ButtonPage;
