import React from 'react';
import './HeroSection.css';
import ButtonPage from '../ButtonPage/ButtonPage';
import ButtonPage1 from '../ButtonPage/ButtonPage1';

const Hero = () => {
  return (
    <section className="hero">
      <div className="hero-content">
        <h3 className="hero-heading">India’s Leading Sustainable </h3>
        
        
        <h1 className="hero-H1" data-text="FITNESS COACH" >FITNESS COACH</h1>
        
        <p className="hero-paragraph">
        <span>⁠Lose 10kgs + in 3 months while eating aloo parathas daily. Yes, it's
100% possible.</span>
        </p>
        <ButtonPage1/>
        <p className="hero-paragraph">
        1500+ people transformed while eating everything they love
        </p>
      </div>
    </section>
  );
};

export default Hero;
