import React, { useState, useEffect } from "react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { textdb } from "../../firebaseConfig"; // Firestore instance
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./AdminPanel.css";

const AdminPanel1 = () => {
  const [bikingLink, setBikingLink] = useState("");

  // Fetch the existing biking link from Firestore
  useEffect(() => {
    const fetchBikingLink = async () => {
      try {
        const docRef = doc(textdb, "settings", "general1"); // Using 'general1' for second panel
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          setBikingLink(docSnap.data().bikingLink);
        }
      } catch (error) {
        console.error("Error fetching biking link: ", error);
        toast.error("Error fetching data");
      }
    };

    fetchBikingLink();
  }, []);

  // Update the biking link in Firestore
  const handleUpdate = async () => {
    if (!bikingLink) {
      toast.error("Please enter a valid link.");
      return;
    }

    try {
      await setDoc(doc(textdb, "settings", "general1"), { bikingLink });
      toast.success("Biking link updated successfully!");
    } catch (error) {
      console.error("Error updating biking link: ", error);
      toast.error("Error updating link");
    }
  };

  return (
    <div className="admin-panel">
      <ToastContainer position="top-right" autoClose={3000} />
      <h2>Admin Panel - Update Biking Link 1</h2>
      <input
        type="text"
        value={bikingLink}
        onChange={(e) => setBikingLink(e.target.value)}
        placeholder="Enter new booking link"
      />
      <button onClick={handleUpdate}>Update Link</button>
    </div>
  );
};

export default AdminPanel1;
